<template>
  <div class="aboutus">
    <div class="banner-container">
      <div class="banner-content">
        <div
          class="card"
          :class="card == 1 ? 'active-card' : ''"
          @click="changeCard(1), addCustomParameter(1)"
        >
          <span class="card-title"
            ><h1>
              {{
                currentLanguage == "sq"
                  ? getDirectorBoard.name
                  : currentLanguage == "en"
                  ? "The Board of Directors"
                  : "Upravni odbor"
              }}
            </h1></span
          >
        </div>
        <div
          class="card"
          :class="card == 2 ? 'active-card' : ''"
          @click="changeCard(2), addCustomParameter(2)"
        >
          <span class="card-title"
            ><h1>
              {{
                currentLanguage == "sq"
                  ? getCeoCategory.name
                  : currentLanguage == "en"
                  ? "Chief Executive Officer"
                  : "Glavni izvršni direktor"
              }}
            </h1></span
          >
        </div>
        <div
          class="card"
          :class="card == 4 ? 'active-card' : ''"
          @click="changeCard(4), addCustomParameter(4)"
        >
          <span class="card-title"
            ><h1>
              {{
                currentLanguage == "sq"
                  ? getOrganogram.name
                  : currentLanguage == "en"
                  ? "Organogram"
                  : "Organogrami"
              }}
            </h1></span
          >
        </div>
        <div
          class="card"
          :class="card == 5 ? 'active-card' : ''"
          @click="changeCard(5), addCustomParameter(5)"
        >
          <span class="card-title"
            ><h1>
              {{
                currentLanguage == "sq"
                  ? getMissioni.name
                  : currentLanguage == "en"
                  ? "Vision and Mission"
                  : "Vizija i misija"
              }}
            </h1></span
          >
        </div>
        <div
          class="card"
          :class="card == 6 ? 'active-card' : ''"
          @click="changeCard(6), addCustomParameter(6)"
        >
          <span class="card-title"
            ><h1>
              {{
                currentLanguage == "sq"
                  ? "Raportet Financiare"
                  : currentLanguage == "en"
                  ? "Financial Reports"
                  : "Finansijski izveštaji"
              }}
            </h1></span
          >
        </div>
        <!--  <div class="card" :class="card == 7 ? 'active-card' : ''" @click="changeCard(7), addCustomParameter(7)">
                    <span class="card-title"><h1>{{currentLanguage == 'sq' ? 'Treguesit financiar' : (currentLanguage == 'en' ? 'Financial indicators' : 'Finansijski pokazatelji')}}</h1></span>
                </div> -->
      </div>
    </div>
    <div class="aboutus-container" v-if="card == 1">
      <div class="title">
        <h1
          sq="Bordi i Drejtorëve"
          en="Board of Directors"
          sr="Upravni odbor"
        ></h1>
      </div>
      <div class="aboutus-content">
        <div
          class="aboutus-post"
          v-for="(item, index) in getAboutUsPosts"
          :key="item.id"
        >
          <div class="media">
            <img :src="getPostMedia(getAboutUsPosts[index].featured_media)" />
          </div>
          <div class="title" v-show="currentLanguage == 'sq'">
            {{ item.meta._sq_post_title }}
          </div>
          <div class="title" v-show="currentLanguage == 'en'">
            {{ item.meta._en_post_title }}
          </div>
          <div class="title" v-show="currentLanguage == 'sr'">
            {{ item.meta._sr_post_title }}
          </div>
          <div
            class="description"
            v-show="currentLanguage == 'sq'"
            v-html="item.meta._sq_post_content"
          ></div>
          <div
            class="description"
            v-show="currentLanguage == 'en'"
            v-html="item.meta._en_post_content"
          ></div>
          <div
            class="description"
            v-show="currentLanguage == 'sr'"
            v-html="item.meta._sr_post_content"
          ></div>
        </div>
      </div>
    </div>
    <div class="ceo-container" v-if="card == 2">
      <div class="ceo-main-container">
        <div class="title">
          <h1
            sq="Kryeshefi Ekzekutiv"
            en="Chief Executive Officer"
            sr="Glavni izvršni direktor"
          ></h1>
        </div>
        <div class="ceo-content">
          <div class="ceo-post">
            <div class="media">
              <img :src="getPostMedia(getCeo?.featured_media)" />
            </div>
            <div class="title" v-show="currentLanguage == 'sq'">
              <h1>{{ getCeo?.meta._sq_post_title }}</h1>
            </div>
            <div class="title" v-show="currentLanguage == 'en'">
              <h1>{{ getCeo?.meta._en_post_title }}</h1>
            </div>
            <div class="title" v-show="currentLanguage == 'sr'">
              <h1>{{ getCeo?.meta._sr_post_title }}</h1>
            </div>
            <div
              class="descri ption"
              v-show="currentLanguage == 'sq'"
              v-html="getCeo?.meta._sq_post_content"
            ></div>
            <div
              class="descri ption"
              v-show="currentLanguage == 'en'"
              v-html="getCeo?.meta._en_post_content"
            ></div>
            <div
              class="descri ption"
              v-show="currentLanguage == 'sr'"
              v-html="getCeo?.meta._sr_post_content"
            ></div>
          </div>
        </div>
        <div class="ceo-content">
          <div class="ceo-post">
            <div class="media">
              <img :src="getPostMedia(getCeos?.featured_media)" />
            </div>
            <div class="title" v-show="currentLanguage == 'sq'">
              <h1>{{ getCeos?.meta._sq_post_title }}</h1>
            </div>
            <div class="title" v-show="currentLanguage == 'en'">
              <h1>{{ getCeos?.meta._en_post_title }}</h1>
            </div>
            <div class="title" v-show="currentLanguage == 'sr'">
              <h1>{{ getCeos?.meta._sr_post_title }}</h1>
            </div>
            <div
              class="descri ption"
              v-show="currentLanguage == 'sq'"
              v-html="getCeos?.meta._sq_post_content"
            ></div>
            <div
              class="descri ption"
              v-show="currentLanguage == 'en'"
              v-html="getCeos?.meta._en_post_content"
            ></div>
            <div
              class="descri ption"
              v-show="currentLanguage == 'sr'"
              v-html="getCeos?.meta._sr_post_content"
            ></div>
          </div>
        </div>
      </div>
      <!-- <div class="ceo-main-container">
           
                <div class="ceo-content">
                    <div class="ceo-post">
                        <div class="media">
                            <img :src="getPostMedia(getCeos?.featured_media)" />
                        </div>
                        <div class="title" v-show="currentLanguage == 'sq'"><h1>{{getCeos?.meta._sq_post_title}}</h1></div>
                        <div class="title" v-show="currentLanguage == 'en'"><h1>{{getCeos?.meta._en_post_title}}</h1></div>
                        <div class="title" v-show="currentLanguage == 'sr'"><h1>{{getCeos?.meta._sr_post_title}}</h1></div>
                        <div class="descri ption" v-show="currentLanguage == 'sq'" v-html="getCeos?.meta._sq_post_content"></div>
                        <div class="descri ption" v-show="currentLanguage == 'en'" v-html="getCeos?.meta._en_post_content"></div>
                        <div class="descri ption" v-show="currentLanguage == 'sr'" v-html="getCeos?.meta._sr_post_content"></div>
                    </div>
                </div>
            </div> -->
    </div>
    <div class="mission-container" v-if="card == 5">
      <div class="title">
        <h1
          sq="Misioni dhe Vizioni"
          en="Mission and Vision"
          sr="Vizija i misija"
        ></h1>
      </div>
      <div class="aboutus-content">
        <div class="aboutus-post">
          <div style="margin-left: 15%; margin-right: 15%">
            <p
              v-html="
                currentLanguage == 'sq'
                  ? getMisioniContent.meta._sq_post_content
                  : currentLanguage == 'en'
                  ? getMisioniContent.meta._en_post_content
                  : getMisioniContent.meta._sr_post_content
              "
            ></p>
          </div>
        </div>
      </div>
    </div>
    <div class="mission-container" v-if="card == 4">
      <div class="title">
        <h1
          sq="Struktura Organizative e Kompanisë"
          en="Organizational Structure of the Company"
          sr="Organizaciona struktura preduzeća"
        ></h1>
      </div>
      <div class="img-wrapper">
        <a
          style="width: 100%"
          v-if="currentLanguage == 'sq'"
          :href="
            getPostMedia(getPosts.filter((x) => x.id == 958)[0].featured_media)
          "
          target="_blank"
        >
          <img
            :src="
              getPostMedia(
                getPosts.filter((x) => x.id == 958)[0].featured_media
              )
            "
        /></a>
        <a
          style="width: 100%"
          v-if="currentLanguage == 'en'"
          :href="
            getPostMedia(getPosts.filter((x) => x.id == 960)[0].featured_media)
          "
          target="_blank"
        >
          <img
            :src="
              getPostMedia(
                getPosts.filter((x) => x.id == 960)[0].featured_media
              )
            "
        /></a>
        <a
          style="width: 100%"
          v-if="currentLanguage == 'sr'"
          :href="
            getPostMedia(getPosts.filter((x) => x.id == 962)[0].featured_media)
          "
          target="_blank"
        >
          <img
            :src="
              getPostMedia(
                getPosts.filter((x) => x.id == 962)[0].featured_media
              )
            "
        /></a>
      </div>
    </div>
    <div class="aboutus-container" v-if="card == 6">
      <div class="title title-about">
        <h1>
          {{
            currentLanguage == "sq"
              ? "Raportet Financiare"
              : currentLanguage == "en"
              ? "Financial Reports"
              : "Finansijski izveštaji"
          }}
        </h1>
      </div>

      <!-- Button Group for report selection -->
      <div class="button-group">
        <button
          :class="reportType === 'annual' ? 'active' : ''"
          @click="setReportType('annual')"
        >
          {{
            currentLanguage == "sq"
              ? "Raportet Vjetore"
              : currentLanguage == "en"
              ? "Annual Reports"
              : "Godišnji Izveštaji"
          }}
        </button>
        <button
          :class="reportType === 'quarterly' ? 'active' : ''"
          @click="setReportType('quarterly')"
        >
          {{
            currentLanguage == "sq"
              ? "Raportet Tre Mujore"
              : currentLanguage == "en"
              ? "Quarterly Reports"
              : "Kvartalni Izveštaji"
          }}
        </button>
        <button
          :class="reportType === 'financialStatements' ? 'active' : ''"
          @click="setReportType('financialStatements')"
        >
          {{
            currentLanguage == "sq"
              ? "Pasqyrat Financiare"
              : currentLanguage == "en"
              ? "Financial Statements"
              : "Finansijski Izveštaji"
          }}
        </button>
      </div>

      <!-- Year selection buttons -->
      <div class="year-selector">
        <button
          v-for="year in [2024, 2023, 2022]"
          :key="year"
          :class="selectedYear === year ? 'selected' : ''"
          @click="selectYear(year)"
        >
          {{ year }}
        </button>
      </div>

      <div
        v-if="selectedYear"
        :class="{
          'report-container annual-report':
            reportType === 'annual' && filteredReports.length > 0,
          'no-annual-report':
            reportType === 'annual' && filteredReports.length === 0,
          'financial-statements': reportType === 'financialStatements',
          'quarterly-reports': reportType === 'quarterly',
        }"
      >
        <!-- Annual Reports -->
        <div class="report-list" v-if="reportType === 'annual'">
          <template v-if="filteredReports.length > 0">
            <div
              v-for="i in filteredReports"
              :key="i.id"
              class="report-card"
              :class="{
                'no-arrow': reportType === 'annual',
                'full-width': filteredReports.length === 1,
              }"
            >
              <div class="report-content">
                <h2>
                  {{
                    currentLanguage == "sq"
                      ? "Raporti Vjetor"
                      : currentLanguage == "en"
                      ? "Annual Report"
                      : "Godišnji Izveštaj"
                  }}
                  {{ selectedYear }}
                </h2>
                <a
                  v-if="i.meta._sq_post_content"
                  v-show="currentLanguage == 'sq'"
                  :href="returnFile(i.meta._sq_post_content)"
                  target="_blank"
                  class="report-link download-button no-arrow"
                >
                  <h3>
                    {{
                      currentLanguage == "sq"
                        ? "Shkarko"
                        : currentLanguage == "en"
                        ? "Download"
                        : "Preuzmi"
                    }}
                  </h3>
                </a>

                <a
                  v-if="i.meta._en_post_content"
                  v-show="currentLanguage == 'en'"
                  :href="returnFile(i.meta._en_post_content)"
                  target="_blank"
                  class="report-link download-button"
                >
                  <h3>
                    {{
                      currentLanguage == "sq"
                        ? "Shkarko"
                        : currentLanguage == "en"
                        ? "Download"
                        : "Preuzmi"
                    }}
                  </h3>
                </a>
                <a
                  v-if="i.meta._sr_post_content"
                  v-show="currentLanguage == 'sr'"
                  :href="returnFile(i.meta._sr_post_content)"
                  target="_blank"
                  class="report-link download-button"
                >
                  <h3>
                    {{
                      currentLanguage == "sq"
                        ? "Shkarko"
                        : currentLanguage == "en"
                        ? "Download"
                        : "Preuzmi"
                    }}
                  </h3>
                </a>
              </div>

              <!-- Right Side: Image Preview -->
              <div class="report-image-container">
                <img
                  src="/images/Annual_Report3.png"
                  alt="Annual Report"
                  class="report-image"
                />
              </div>
            </div>
          </template>
          <!-- No Reports Available Message -->
          <div v-else class="no-report-container">
            <p class="no-report-text">
              {{
                currentLanguage == "sq"
                  ? "Nuk ka raport vjetor për këtë vit"
                  : currentLanguage == "en"
                  ? "No annual report for this year"
                  : "Nema finansijskog izveštaja za ovu godinu"
              }}
            </p>
          </div>
        </div>

        <!-- Quarterly Reports -->
        <div class="report-list" v-if="reportType === 'quarterly'">
          <template v-if="getQuarterlyReports.length > 0">
            <div
              v-for="indicator in getQuarterlyReports"
              :key="indicator.id"
              class="report-card"
            >
              <a
                v-if="indicator.meta._sq_post_content"
                v-show="currentLanguage == 'sq'"
                :href="returnFile(indicator.meta._sq_post_content)"
                target="_blank"
                class="report-link"
              >
                <h3>{{ indicator.meta._sq_post_title }}</h3>
              </a>
              <a
                v-if="indicator.meta._en_post_content"
                v-show="currentLanguage == 'en'"
                :href="returnFile(indicator.meta._en_post_content)"
                target="_blank"
                class="report-link"
              >
                <h3>{{ indicator.meta._en_post_title }}</h3>
              </a>
              <a
                v-if="indicator.meta._sr_post_content"
                v-show="currentLanguage == 'sr'"
                :href="returnFile(indicator.meta._sr_post_content)"
                target="_blank"
                class="report-link"
              >
                <h3>{{ indicator.meta._sr_post_title }}</h3>
              </a>
            </div>
          </template>
          <!-- No Reports Available Message -->
          <div v-else class="no-report-container">
            <p class="no-report-text">
              {{
                currentLanguage == "sq"
                  ? "Nuk ka raporte tre mujore për këtë vit"
                  : currentLanguage == "en"
                  ? "No quarterly reports for this year"
                  : "Nema kvartalnih izveštaja za ovu godinu"
              }}
            </p>
          </div>
        </div>

        <!-- Financial Statements -->
        <div class="report-list" v-if="reportType === 'financialStatements'">
          <template v-if="getFinancialStatements.length > 0">
            <div
              v-for="statement in getFinancialStatements"
              :key="statement.id"
              class="report-card"
            >
              <a
                v-if="statement.meta._sq_post_content"
                v-show="currentLanguage == 'sq'"
                :href="returnFile(statement.meta._sq_post_content)"
                target="_blank"
                class="report-link"
              >
                <h3>{{ statement.meta._sq_post_title }}</h3>
              </a>
              <a
                v-if="statement.meta._en_post_content"
                v-show="currentLanguage == 'en'"
                :href="returnFile(statement.meta._en_post_content)"
                target="_blank"
                class="report-link"
              >
                <h3>{{ statement.meta._en_post_title }}</h3>
              </a>
              <a
                v-if="statement.meta._sr_post_content"
                v-show="currentLanguage == 'sr'"
                :href="returnFile(statement.meta._sr_post_content)"
                target="_blank"
                class="report-link"
              >
                <h3>{{ statement.meta._sr_post_title }}</h3>
              </a>
            </div>
          </template>
          <!-- No Reports Available Message -->
          <div v-else class="no-report-container">
            <p class="no-report-text">
              {{
                currentLanguage == "sq"
                  ? "Nuk ka pasqyra financiare për këtë vit"
                  : currentLanguage == "en"
                  ? "No financial statements for this year"
                  : "Nema finansijskih izveštaja za ovu godinu"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="aboutus-container" v-if="card == 7">
      <div class="title">
        <h1
          sq="Treguesit Financiar"
          en="Financial indicators"
          sr="Finansijski pokazatelji"
        ></h1>
      </div>
      <div class="indicators-container">
        <div
          class="indicators"
          :class="card == 7 ? 'active-card' : ''"
          v-for="i in getIndicatorsReportsPost"
          :key="i.id"
        >
          <a
            v-show="currentLanguage == 'sq'"
            :href="returnFile(i.meta._sq_post_content)"
            target="_blank"
            class="indicator-link"
          >
            <span class="card-title">
              <h1>{{ i.meta._sq_post_title }}</h1>
            </span>
          </a>
          <a
            v-show="currentLanguage == 'en'"
            :href="returnFile(i.meta._en_post_content)"
            target="_blank"
            class="indicator-link"
          >
            <span class="card-title">
              <h1>{{ i.meta._en_post_title }}</h1>
            </span>
          </a>
          <a
            v-show="currentLanguage == 'sr'"
            :href="returnFile(i.meta._sr_post_content)"
            target="_blank"
            class="indicator-link"
          >
            <span class="card-title">
              <h1>{{ i.meta._sr_post_title }}</h1>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { externalScript } from "../../assets/external";

export default {
  data() {
    return {
      card: 1,
      reportType: "annual",
      selectedYear: 2024,
    };
  },
  methods: {
    ...mapActions([
      "fetchCategories",
      "fetchPosts",
      "fetchMedia",
      "fetchMedias",
    ]),
    getPostMedia(id) {
      let media = this.getMedias.find((x) => x.id == id);
      return media?.source_url;
    },
    changeCard(cardNumber, reportType = "annual", year = 2024) {
      this.card = cardNumber;
      if (cardNumber === 6) {
        this.reportType = reportType;
        this.selectedYear = year;
      }
    },
    addCustomParameter(i) {
      this.$router.push({ query: { form: i } });
    },
    returnFile(e) {
      const regex = /href="([^"]+)"/;
      const match = e.match(regex);
      if (match) return match[1];
      return;
    },
    setReportType(type) {
      this.reportType = type;
    },
    selectYear(year) {
      this.selectedYear = year;
    },
    getEnglish() {
      return this.getPosts.filter((x) => x.id == 960)[0].featured_media;
    },
    getAlbanian() {
      return this.getPosts.filter((x) => x.id == 958)[0].featured_media;
    },
    getSerbian() {
      return this.getPosts.filter((x) => x.id == 962)[0].featured_media;
    },
    escapeHtml(input) {
      const tempElement = document.createElement("div");

      tempElement.innerHTML = input;

      const fragment = document.createDocumentFragment();

      while (tempElement.firstChild) {
        fragment.appendChild(tempElement.firstChild);
      }

      return fragment;
    },
  },
  computed: {
    ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
    getAboutUs() {
      return this.getCategories.find((x) => x.slug === "about-us");
    },
    getDirectorBoard() {
      return this.getCategories.find((x) => x.id == 59);
    },
    getCeoCategory() {
      return this.getCategories.find((x) => x.id == 60);
    },
    getOrganogram() {
      return this.getCategories.find((x) => x.id == 61);
    },
    getMissioni() {
      return this.getCategories.find((x) => x.id == 65);
    },
    getFinancialReports() {
      return this.getCategories.find((x) => x.id == 67);
    },
    getAboutUsPosts() {
      return this.sortedPosts.filter(
        (x) =>
          x.categories[0] == this.getAboutUs.id && x.id !== 95 && x.id !== 1264
      );
    },
    sortedPosts() {
      return [...this.getPosts].sort((a, b) => a.id - b.id);
    },
    getCeo() {
      return this.sortedPosts.filter((x) => x.id === 95)[0];
    },
    getCeos() {
      return this.sortedPosts.filter((x) => x.id === 1264)[0];
    },
    getFinancialReportsPost() {
      return this.getPosts
        .filter((post) => post.categories.includes(67))
        .sort((a, b) => b.id - a.id);
    },
    getIndicatorsReportsPost() {
      if (this.card === 7) {
        return this.getPosts
          .filter((post) => post.categories.includes(75))
          .sort(
            (a, b) => new Date(b.meta._post_date) - new Date(a.meta._post_date)
          );
      }
      return this.getPosts.filter((post) => post.categories.includes(75));
    },
    getMisioniContent() {
      return this.sortedPosts.filter((x) => x.id === 676)[0];
    },
    filteredReports() {
      if (!this.selectedYear) return [];
      return this.getPosts.filter(
        (post) =>
          post.categories.includes(67) &&
          post.meta._sq_post_title &&
          post.meta._sq_post_title.includes(this.selectedYear.toString())
      );
    },
    getFinancialStatements() {
      return this.getPosts.filter(
        (post) =>
          post.categories.includes(75) && // Ensure it's a financial statement
          post.meta._sq_post_title.includes("Pasqyrat Financiare") && // Ensure it's "Pasqyrat Financiare"
          post.meta._sq_post_title.includes(this.selectedYear.toString()) // Dynamically filter by selected year
      );
    },
    getQuarterlyReports() {
      return this.getIndicatorsReportsPost.filter(
        (post) =>
          !post.meta._sq_post_title.includes("Pasqyrat Financiare") && // Exclude Pasqyrat Financiare
          post.meta._sq_post_title.includes(this.selectedYear.toString()) // Filter by selected year
      );
    },
    currentLanguage() {
      let name = "lang=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },

  async mounted() {
    await this.fetchMedias();
    await this.fetchCategories();
    await this.fetchPosts();
    if (this.$route.query.form !== undefined) {
      this.changeCard(this.$route.query.form);
    }
    setTimeout(() => externalScript.reloadLanguage(), 400);
  },
  watch: {
    currentLanguage(newValue) {
      this.currentLanguageValue = newValue;

      window.location.reload();
    },
  },
};
</script>

<style>
.ceo-post .title {
  padding-bottom: 30px;
}

.ceo-post .description p {
  padding: 20px;
  font-size: 18px;
}

.ceo-post .description {
  text-align: left;
  padding-left: 10%;
  padding-right: 10%;
}

.aboutus .mission-container {
  margin-left: 0;
  padding-top: 75px;
  margin-right: 0;
  padding-right: 0;
  padding-bottom: 75px;
}

.raports {
  justify-content: center;
  display: flex;
  justify-content: center;
  width: 50%;
  padding: 20px;
  border-radius: 10px;
}

.aboutus-container {
  padding: 20px;
}

.title h1 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.indicators-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 5%;
}

.indicators {
  color: white;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.indicators:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.indicator-link {
  color: white;
  text-decoration: none;
  display: block;
}

.card-title h1 {
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .card-title h1 {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .card-title h1 {
    font-size: 0.9rem;
  }
  .indicators {
    padding: 10px;
  }
}

@import "../../assets/styles/aboutus.css";
</style>
